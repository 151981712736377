import React from "react";

const Header = () => {
  return (
    <div>
      <header className="banner">
        <div className="container">
          <div className="row">
            <div className="col nav-col nav-col-primary">
              <button
                className="hamburger hamburger--vortex"
                type="button"
                aria-expanded="false"
                aria-controls="nav-mobile"
              >
                <span className="sr-only">Toggle Mobile Nav</span>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav className="nav-primary" id="nav-mobile">
                <div className="menu-primary-navigation-container">
                  <ul id="menu-primary-navigation" className="nav">
                    <li
                      id="menu-item-671"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-671 nav-item dropdown-toggle dropdown-header nav-link dropdown-header"
                    >
                      <button
                        className="dropdown-header nav-link"
                        title="Why Sentient"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="menu-item-dropdown-671"
                      >
                        Why ROCKETSPEED
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          id="menu-item-703"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-703 nav-item"
                        >
                          <a
                            title="The ROCKETSPEED Experience"
                            href="/experience"
                            className="dropdown-item"
                          >
                            The ROCKETSPEED Experience
                          </a>
                        </li>
                        <li
                          id="menu-item-712"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-712 nav-item"
                        >
                          <a
                            title="Sustainability"
                            href="/sustainability"
                            className="dropdown-item"
                          >
                            Sustainability
                          </a>
                        </li>
                        {/* <li
                          id="menu-item-720"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-720 nav-item"
                        >
                          <a
                            title="Technology"
                            href="/technology"
                            className="dropdown-item"
                          >
                            Technology
                          </a>
                        </li> */}
                      </ul>
                    </li>
                    <li
                      id="menu-item-381"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-381 nav-item dropdown-toggle dropdown-header nav-link dropdown-header"
                    >
                      <button
                        className="dropdown-header nav-link"
                        title="How It Works"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="menu-item-dropdown-381"
                      >
                        How It Works
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          id="menu-item-1943"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1943 nav-item"
                        >
                          <a
                            title="International Travel"
                            href="/international-travel"
                            className="dropdown-item"
                          >
                            International Travel
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-678"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-678 na dropdown-toggle dropdown-header nav-link dropdown-headerv-item"
                    >
                      <button
                        className="dropdown-header nav-link"
                        title="Safety"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="menu-item-dropdown-678"
                      >
                        Safety
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          id="menu-item-1128"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1128 nav-item"
                        >
                          <a
                            title="ROCKETSPEED Certified®"
                            href="/certified"
                            className="dropdown-item"
                          >
                            ROCKETSPEED Certified®
                          </a>
                        </li>
                        <li
                          id="menu-item-1939"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1939 nav-item"
                        >
                          <a
                            title="Data Safety"
                            href="/datasafety"
                            className="dropdown-item"
                          >
                            Data Safety
                          </a>
                        </li>
                        <li
                          id="menu-item-1926"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1926 nav-item"
                        >
                          <a
                            title="Covid 19 Safety"
                            href="/covid"
                            className="dropdown-item"
                          >
                            Covid 19 Safety
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-970"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-970 nav-item"
                    >
                      <a
                        title="Atmosphere"
                        href="/testimonies"
                        className="nav-link"
                      >
                        Testimonies
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="menu-mobile-primary-navigation-container">
                  <ul id="menu-mobile-primary-navigation" className="nav">
                    <li
                      id="menu-item-726"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-726 nav-item dropdown-toggle dropdown-header nav-link dropdown-header"
                    >
                      <button
                        className="dropdown-header nav-link"
                        title="Why Sentient"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="menu-item-dropdown-726"
                      >
                        Why ROCKETSPEED
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          id="menu-item-758"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-758 nav-item"
                        >
                          <a
                            title="The ROCKETSPEED Experience"
                            href="/experience"
                            className="dropdown-item"
                          >
                            The ROCKETSPEED Experience
                          </a>
                        </li>
                        <li
                          id="menu-item-757"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-757 nav-item"
                        >
                          <a
                            title="Sustainability"
                            href="/sustainability"
                            className="dropdown-item"
                          >
                            Sustainability
                          </a>
                        </li>
                        <li
                          id="menu-item-756"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-756 nav-item"
                        >
                          <a
                            title="Technology"
                            href="/technology"
                            className="dropdown-item"
                          >
                            Technology
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-733"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-733 nav-item dropdown-toggle dropdown-header nav-link dropdown-header"
                    >
                      <button
                        className="dropdown-header nav-link"
                        title="How it Works"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="menu-item-dropdown-733"
                      >
                        How it Works
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          id="menu-item-759"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-759 nav-item"
                        >
                          <a
                            title="ROCKETSPEED Jet Card"
                            href="#"
                            className="dropdown-item"
                          >
                            ROCKETSPEED Jet Card
                          </a>
                        </li>
                        <li
                          id="menu-item-760"
                          className="menu-item menu-item-type-post_type_archive menu-item-object-sj_cpt_aircraft menu-item-760 nav-item"
                        >
                          <a
                            title="Aircraft Categories"
                            href="#"
                            className="dropdown-item"
                          >
                            Aircraft Categories
                          </a>
                        </li>
                        <li
                          id="menu-item-1941"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1941 nav-item"
                        >
                          <a
                            title="International Private Jet Travel"
                            href="international-private-jet-travel/index.html"
                            className="dropdown-item"
                          >
                            International Private Jet Travel
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-738"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-738 nav-item  dropdown-toggle dropdown-header nav-link dropdown-header"
                    >
                      <button
                        className="dropdown-header nav-link"
                        title="Safety"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="menu-item-dropdown-738"
                      >
                        Safety
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          id="menu-item-1924"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1924 nav-item"
                        >
                          <a
                            title="Commitment to Safety"
                            href="#"
                            className="dropdown-item"
                          >
                            Commitment to Safety
                          </a>
                        </li>
                        <li
                          id="menu-item-1925"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1925 nav-item"
                        >
                          <a
                            title="ROCKETSPEED Certified®"
                            href="#"
                            className="dropdown-item"
                          >
                            ROCKETSPEED Certified®
                          </a>
                        </li>
                        <li
                          id="menu-item-1940"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1940 nav-item"
                        >
                          <a
                            title="Data Safety"
                            href="#"
                            className="dropdown-item"
                          >
                            Data Safety
                          </a>
                        </li>
                        <li
                          id="menu-item-1923"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1923 nav-item"
                        >
                          <a
                            title="Covid 19 Safety"
                            href="#"
                            className="dropdown-item"
                          >
                            Covid 19 Safety
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-1922"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1922 nav-item"
                    >
                      <a
                        title="Atmosphere"
                        href="/Testimonies"
                        className="nav-link"
                      >
                        Testimonies
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="menu-mobile-utility-navigation-container">
                  <ul id="menu-mobile-utility-navigation" className="nav">
                    <li
                      id="menu-item-684"
                      className="btn btn-primary menu-item menu-item-type-custom menu-item-object-custom menu-item-684"
                    >
                      <a href="mailto:rocketspeed813@gmail.com">Mail Us</a>
                    </li>
                    {/* <li id="menu-item-685" className="btn btn-secondary menu-item menu-item-type-custom menu-item-object-custom menu-item-685"><a target="_blank" rel="noopener" href="#">Login</a></li> */}
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col nav-col nav-col-brand">
              <span className="brand">
                <img
                  src="wp-content/themes/Sentient-2022/dist/images/sentientjet-logo_ff2cc4e9.png"
                  alt="ROCKETSPEED Jet"
                />
              </span>
            </div>
            <div className="col nav-col nav-col-utility">
              <nav
                className="header-utility btn-toolbar"
                aria-label="Header Utility"
              >
                <div className="menu-utility-nav-container">
                  <ul id="menu-utility-nav" className="nav">
                    <li
                      id="menu-item-229"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-229"
                    >
                      <a href="mailto:rocketspeed813@gmail.com">Mail Us</a>
                    </li>
                    {/* <li id="menu-item-1169" className="nav-btn nav-btn-primary menu-item menu-item-type-post_type menu-item-object-page menu-item-1169"><a title="Contact Sentient" href="#">Learn More</a></li> */}
                    {/* <li id="menu-item-231" className="nav-btn nav-btn-secondary menu-item menu-item-type-custom menu-item-object-custom menu-item-231"><a title="Cardholder Login" target="_blank" rel="noopener" href="#">Login</a></li> */}
                  </ul>
                </div>
                <div className="menu-mobile-secondary-navigation-container">
                  <ul id="menu-mobile-secondary-navigation" className="nav">
                    <li
                      id="menu-item-2495"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2495"
                    >
                      <a href="/learn-more">Get A Jet Card</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
