import React from 'react'

const Experience = () => {
  return (
    <div>
         <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
            <section className="primary-hero primary-hero text-dark">
    <div className="image-container">
          <span className="scrim-light-ttb-1 scrim-height-30-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/1.1_Why_Hero_Mobile_x2.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/1.1_Why_Hero-1024x499.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/1.1_Why_Hero.jpg"/>
      <img
        data-src="./wp-content/uploads/2022/02/1.1_Why_Hero-1024x499.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    <div className="container">
    <div className="row">
            <div className="col-12 col-lg-5">
        <div
          className="content-container"
          data-gtm-component-title="Leading the way"
          data-gtm-section-title=""
        >
          <h1 className="">Leading the way</h1>
          
          <div className="body-copy body-xl col-lg-9 px-0"><p><span style={{fontWeight: 400}}>For twenty five years, ROCKETSPEED has been a pioneer in private aviation — inventing industry firsts like our Jet Card — and developing a premium, yet practical, flying experience.</span></p>
</div>
                    <div className="cta-container">
            <a href="./private-jet-cards" target="_self" className="btn btn-primary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Our Jet Cards">Our Jet Cards</a>
          </div>
                  </div>
      </div>
    </div>
  </div>
</section>
              <section className="promo-with-cropped-circle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-lg-5 offset-lg-1 ">
        <div className="image-container image-align-top">
                    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.1_Why_Service-800x800.jpg" alt="" className="img-fluid rounded-circle lazyload"/>
        </div>
      </div>
      <div
        className="text-container col-md-6 col-lg-4 offset-lg-1  "
        data-gtm-component-title="World-className service"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">World-className service</h2>
        
        <div className="body-copy"><p><span style={{fontWeight: 400}}>Our professionals are committed to making your experience the best it can possibly be, both on and off the ground. Supported by an extensive 24/7 customer service operation, we are dedicated to delivering truly personalized service.</span></p>
</div>
              </div>
    </div>
  </div>
</section>
          <section className="promo-with-unbounded-photo align-left">
    <div className="image-container">
    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.1_Why_Safety_2x-1024x585.jpg" alt="" className="img-fluid lazyload"/>
  </div>
    <div className="container">
    <div className="row">
      <div
        className="col-md-4 offset-md-1"
        data-gtm-component-title="Committed to safety"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Committed to safety</h2>
        
        <div className="body-lg"><p><span style={{fontWeight: 400}}>ROCKETSPEED Jet is a leader in safety. At the center of our efforts is ROCKETSPEED Certified, a set of safety standards that were the first of their kind in private aviation, and remain among the most rigorous in the field.</span></p>
</div>
        <div className="cta-container">
      <a href="../private-jet-safety" target="_self" className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Learn about ROCKETSPEED safety">Learn about ROCKETSPEED safety</a>
  </div>
      </div>
    </div>
  </div>
</section>
          <section className="promo-with-cropped-circle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-lg-5 offset-lg-1 ">
        <div className="image-container image-align-bottom">
                    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/derby-800x800.jpg" alt="" className="img-fluid rounded-circle lazyload"/>
        </div>
      </div>
      <div
        className="text-container col-md-6 col-lg-4 offset-lg-1  "
        data-gtm-component-title="Partner benefits"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Partner benefits</h2>
        
        <div className="body-copy"><p><span style={{fontWeight: 400}}>Jet Card Owners enjoy access to first-className resorts and residence clubs, one-of-a-kind experiences, premium retailers, and more.</span></p>
</div>
              </div>
    </div>
  </div>
</section>
          <section className="photo-blade text-dark">
    <div className="image-container">
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/1.1_Why_Innovation_mobile_2x.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/1.1_Why_Innovation-1024x427.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/1.1_Why_Innovation.jpg"/>
      <img
        data-src="./wp-content/uploads/2022/02/1.1_Why_Innovation-1024x427.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    {/* <div className="container">
    <div className="row align-items-start">
      <div className="col-sm-10 col-lg-4 offset-lg-8">
        <div className="content-container" data-gtm-component-title="Innovation is in our DNA">
          <h2 className="">Innovation is in our DNA</h2>
          <div className="body-copy"><p><span style={{fontWeight: 400}}>From inventing a new way for people to experience private jet travel to our advanced mobile app, we’re always looking for ways to build on our promise of offering you a more thoughtful way to fly.</span></p>
</div>
          <div className="cta-container">
      <a href="#" target="_self" className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="See how we use technology">See how we use technology</a>
  </div>
        </div>
      </div>
    </div>
  </div> */}
</section>
          <section className="promo-with-unbounded-photo align-right">
    <div className="image-container">
    <img src="#" data-src="wp-content/uploads/2022/02/1.1_Why_Sustainability_Desktop-1024x585.jpg" alt="" className="img-fluid lazyload"/>
  </div>
    <div className="container">
    <div className="row">
      <div
        className="col-md-4 offset-md-7"
        data-gtm-component-title="Elevating sustainability"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Elevating sustainability</h2>
        
        <div className="body-lg"><p><span style={{fontWeight: 400}}>We have partnered with the leader in sustainable aviation, 4Air, to significantly reduce the environmental impact of your private air travel.</span></p>
</div>
        <div className="cta-container">
      <a href="#" target="_self" className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Learn more">Learn more</a>
  </div>
      </div>
    </div>
  </div>
</section>
          <section className="cta-blade text-dark">
          <div className="image-container">
                  <span className="scrim-light-ltr-1 scrim-width-80-1" aria-hidden="true"></span>
                        <picture>
          <source media="(max-width: 768px)" srcset="./wp-content/uploads/2022/02/1.1_Why_CTA_FamilMobile.jpg"/>
          <source media="(max-width: 1024px)" srcset="./wp-content/uploads/2022/02/1.1_Why_CTA_Family-1024x256.jpg"/>
          <source media="(min-width: 1025px)" srcset="./wp-content/uploads/2022/02/1.1_Why_CTA_Family.jpg"/>
          <img
            src="./wp-content/uploads/2022/02/1.1_Why_CTA_Family-1024x256.jpg"
            alt=""/>
        </picture>
      </div>
    
    <div className="container">
      <div className="row">
        
        <div className="col-11 col-lg-6">
          <div className="content-container" data-gtm-component-title="Ready for a more thoughtful way to fly?">
            <h2 className="">Ready for a more thoughtful way to fly?</h2>
                          {/* <div className="cta-container">
                <a href="./private-jet-cards" target="_self" className="btn btn-outline-secondary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Get started">Get started</a>
              </div> */}
                      </div>
        </div>
      </div>
    </div>
  </section>
              </main>
              </div>
    </div>
    </div>
  )
}

export default Experience