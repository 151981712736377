import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const LandingPage = () => {
  return (
    <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
          <div data-page-category="home">
            <section class="primary-hero  text-dark">
              <div class="image-container">
                <span
                  class="scrim-light-ttb-1 scrim-height-30-1"
                  aria-hidden="true"
                ></span>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    data-srcset="./wp-content/uploads/2022/02/0.0_Home_Hero_Mobile_x2.jpg"
                  />
                  <source
                    media="(max-width: 1024px)"
                    data-srcset="./wp-content/uploads/2022/01/a-more-thoughtful-bg-desktop-1024x499.jpg"
                  />
                  <source
                    media="(min-width: 1025px)"
                    data-srcset="./wp-content/uploads/2022/01/a-more-thoughtful-bg-desktop-scaled.jpg"
                  />
                  <img
                    data-src="./wp-content/uploads/2022/01/a-more-thoughtful-bg-desktop-1024x499.jpg"
                    alt=""
                    class="lazyload"
                  />
                </picture>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-5">
                    <div
                      class="content-container"
                      data-gtm-component-title="A more thoughtful way to fly."
                      data-gtm-section-title=""
                    >
                      <h1 class="">A more thoughtful way to fly.</h1>

                      <div class="body-copy body-xl col-lg-9 px-0">
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            ROCKETSPEED began 25 years ago with one belief:
                            people deserve a better way to book and fly private
                            jet travel. Our industry first Jet Card set the
                            standard for innovation—and we’ve never looked back.
                          </span>
                        </p>
                      </div>
                      <div class="cta-container">
                        <a
                          href="./private-jet-cards"
                          target="_self"
                          class="btn btn-primary"
                          data-gtm-click="link_click"
                          data-gtm-event=""
                          data-gtm-click-text="Learn More"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="offset-columns">
              <div className="has-background">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col-sm-8 col-md-6 col-lg-4 offset-lg-1">
                          <div className="image-container image-container-top">
                            <img
                              src="#"
                              data-src="wp-content/uploads/2022/01/Sentient-Jet-SJ25-Card.png"
                              alt=""
                              className="img-fluid card-image elevation-float lazyload"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-around">
                        <div className="col-md-6 col-lg-4">
                          <div className="column-content">
                            <div
                              className="content-wrapper"
                              data-gtm-component-title="SJ25+ Jet Card"
                            >
                              <h2 className="h3">SJ25+ Jet Card</h2>
                              <div className="body-lg">
                                <p className="p1">
                                  <span style={{ fontWeight: 400 }}>
                                    The SJ25+ Card offers dedicated access to
                                    ROCKETSPEED Certified Mid, Super-Mid and
                                    Large Cabin Jet travel.
                                    <p></p>
                                    {" "}
                                  </span>
                                  
                                </p>
                                
                              </div>
                              {/* <a href="./private-jet-cards/midsize-large-private-jet-card/index.html" target="_self" aria-label="Learn more about SJ25+ Jet Card" title="Learn more about SJ25+ Jet Card"  className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Learn More">Learn More</a> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="column-content d-none d-md-block">
                            <div
                              className="content-wrapper"
                              data-gtm-component-title="SJ25 Jet Card"
                            >
                              <h2 className="h3">SJ25 Jet Card</h2>
                              <div className="body-lg">
                                <p>
                                  <span style={{ fontWeight: 400 }}>
                                    ROCKETSPEED Jet’s 25-Hour Jet Card (SJ25)
                                    combines flexibility with outstanding
                                    quality and value for Light Jet flyers.
                                  </span>
                                </p>
                              </div>
                              {/* <a href="./private-jet-cards/light-private-jet-card/index.html" target="_self" aria-label="Learn more about SJ25 Jet Card" title="Learn more about SJ25 Jet Card"  className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Learn More">Learn More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8 col-md-6 offset-md-6 col-lg-4 offset-lg-7">
                          <div className="image-container image-container-btm">
                            <img
                              src="#"
                              data-src="wp-content/uploads/2022/01/Sentient-Jet-SJ25-Card-1.png"
                              alt=""
                              className="img-fluid card-image elevation-float lazyload"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </section>
              <section>
              <div className="no-background">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="column-content d-block d-md-none">
                        <div
                          className="content-wrapper"
                          data-gtm-component-title="SJ25 Jet Card"
                        >
                          <h2 className="h3">SJ25 Jet Card</h2>
                          <div className="body-lg">
                            <p>
                              <span style={{ fontWeight: 400 }}>
                                ROCKETSPEED Jet’s 25-Hour Jet Card (SJ25)
                                combines flexibility with outstanding quality
                                and value for Light Jet flyers.
                              </span>
                            </p>
                          </div>
                          {/* <a href="private-jet-cards/light-private-jet-card/index.html" target="_self" aria-label="Learn more about SJ25 Jet Card" title="Learn more about SJ25 Jet Card"  className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Learn More">Learn More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="photo-blade text-dark">
                                    <div className="image-container">
                                        <picture>
                                        <source media="(max-width: 768px)" data-srcset="wp-content/uploads/2022/02/0.0_Booking_App_Mobile_2x-1.jpg"/>
                                        <source media="(max-width: 1024px)" data-srcset="wp-content/uploads/2022/02/0.0_Booking_App-1024x427.jpg"/>
                                        <source media="(min-width: 1025px)" data-srcset="wp-content/uploads/2022/02/0.0_Booking_App-scaled.jpg"/>
                                        <img
                                            data-src="wp-content/uploads/2022/02/0.0_Booking_App-1024x427.jpg"
                                            alt=""
                                            className="lazyload"/>
                                        </picture>
                                    </div>
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <div className="col-sm-10 col-lg-4">
                                                <div className="content-container" data-gtm-component-title="Real-time booking in the palm of your hand">
                                                <h2 className="">Real-time booking in the palm of your hand</h2>
                                                    <div className="body-copy"><p><span style={{fontWeight: 400}}>At Sentient, we know your time is your most valuable asset — that’s why we&#8217;ve created a simple and seamless booking experience.</span></p>
                                                    </div>
                                                    <div className="cta-container">
                                                        <div className="download-cta">
                                                                            <a href="https://apps.apple.com/us/app/sentient-jet/id1123758849" target="_blank" className="btn btn-primary btn-app-apple" data-gtm-click="link_click" data-gtm-event="app_download" data-gtm-click-text="Download in the App Store">Download in the App Store</a>
                                                                                    <a href="https://play.google.com/store/apps/details?id=com.sentientjet&amp;hl=en_US&amp;gl=US" target="_blank" className="btn btn-primary btn-app-google" data-gtm-click="link_click" data-gtm-event="app_download" data-gtm-click-text="Get it on Google Play">Get it on Google Play</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                </section> */}
            <section className="photo-blade text-dark">
              <div className="image-container">
                <span
                  className="scrim-light-rtl-1 scrim-width-50-1"
                  aria-hidden="true"
                ></span>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    data-srcset="wp-content/uploads/2022/02/0.0_Sentient_Experience_Mobile_x2.jpg"
                  />
                  <source
                    media="(max-width: 1024px)"
                    data-srcset="wp-content/uploads/2022/02/0.0_Sentient_Experience-1024x427.jpg"
                  />
                  <source
                    media="(min-width: 1025px)"
                    data-srcset="wp-content/uploads/2022/02/0.0_Sentient_Experience-scaled.jpg"
                  />
                  <img
                    data-src="wp-content/uploads/2022/02/0.0_Sentient_Experience-1024x427.jpg"
                    alt=""
                    className="lazyload"
                  />
                </picture>
              </div>
              <div className="container">
                <div className="row align-items-start">
                  <div className="col-sm-10 col-lg-4 offset-lg-8">
                    <div
                      className="content-container"
                      data-gtm-component-title="The ROCKETSPEED Experience"
                    >
                      <h2 className="">The ROCKETSPEED Experience</h2>
                      <div className="body-copy">
                        <p>
                          ROCKETSPEED understands your flight is just one part
                          of your journey. We strive to ensure your ROCKETSPEED
                          experience extends beyond your time in the air.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="promo-with-cropped-circle">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-5 offset-lg-1 order-md-2">
                    <div className="image-container image-align-top">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/02/0.0_Culture_Service_x2-800x800.jpg"
                        alt=""
                        className="img-fluid rounded-circle lazyload"
                      />
                    </div>
                  </div>
                  <div
                    className="text-container col-md-6 col-lg-4 offset-lg-1 order-md-1 "
                    data-gtm-component-title="A culture of service"
                    data-gtm-section-title=""
                  >
                    <h2 className="icon-heading">A culture of service</h2>

                    <div className="body-copy">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          At the heart of our operation is a seasoned,
                          passionate team of professionals who oversee every
                          detail of every flight. Our Client Management Team is
                          available to you 24/7.
                        </span>
                      </p>
                    </div>
                    {/* <div className="cta-container">
                      <a
                        href="sentient-experience/index.html"
                        target="_self"
                        className="btn btn-tertiary"
                        data-gtm-click="link_click"
                        data-gtm-event=""
                        data-gtm-click-text="The ROCKETSPEED Experience"
                      >
                        The ROCKETSPEED Experience
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            <section className="photo-blade text-dark">
              <div className="image-container">
                <picture>
                  <source
                    media="(max-width: 768px)"
                    data-srcset="wp-content/uploads/2022/02/0.0_Safety_Certified_Mobile_2x.jpg"
                  />
                  <source
                    media="(max-width: 1024px)"
                    data-srcset="wp-content/uploads/2022/02/0.0_Safety_Certified-1024x427.jpg"
                  />
                  <source
                    media="(min-width: 1025px)"
                    data-srcset="wp-content/uploads/2022/02/0.0_Safety_Certified-scaled.jpg"
                  />
                  <img
                    data-src="wp-content/uploads/2022/02/0.0_Safety_Certified-1024x427.jpg"
                    alt=""
                    className="lazyload"
                  />
                </picture>
              </div>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-sm-10 col-lg-4 offset-lg-8">
                    <div
                      className="content-container"
                      data-gtm-component-title="Safety is everything"
                    >
                      <h2 className="">Safety is everything</h2>
                      <div className="body-copy">
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            ROCKETSPEED offers an unparalleled safety program,
                            with proprietary technology, expert advisors, and
                            personalized safety checks every time you fly.
                          </span>
                        </p>
                      </div>
                        {/* <div className="cta-container">
                          <a
                            href="private-jet-safety/index.html"
                            target="_self"
                            className="btn btn-tertiary"
                            data-gtm-click="link_click"
                            data-gtm-event=""
                            data-gtm-click-text="ROCKETSPEED on Safety"
                          >
                            ROCKETSPEED on Safety
                          </a>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="photo-blade text-light">
              <div className="image-container">
                <picture>
                  <source
                    media="(max-width: 768px)"
                    data-srcset="./wp-content/uploads/2022/02/0.0_Emissions_Free_Flying_Mobile_x2.jpg"
                  />
                  <source
                    media="(max-width: 1024px)"
                    data-srcset="./wp-content/uploads/2022/02/0.0_Emissions_Free_Flying_Desktop-1-1024x427.jpg"
                  />
                  <source
                    media="(min-width: 1025px)"
                    data-srcset="./wp-content/uploads/2022/02/0.0_Emissions_Free_Flying_Desktop-1-scaled.jpg"
                  />
                  <img
                    data-src="./wp-content/uploads/2022/02/0.0_Emissions_Free_Flying_Desktop-1-1024x427.jpg"
                    alt=""
                    className="lazyload"
                  />
                </picture>
              </div>
              <div className="container">
                <div className="row align-items-start">
                  <div className="col-sm-10 col-lg-4">
                    <div
                      className="content-container"
                      data-gtm-component-title="Emissions free flying"
                    >
                      <h2 className="">Emissions free flying</h2>
                      <div className="body-copy">
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            We are committed to transcending industry
                            sustainability goals. Every flight flown by
                            ROCKETSPEED is offset to be both carbon and
                            emissions neutral.
                          </span>
                        </p>
                      </div>
                      <div className="cta-container">
                        <a
                          href="private-jet-sustainability/index.html"
                          target="_self"
                          className="btn btn-tertiary btn-tertiary-light"
                          data-gtm-click="link_click"
                          data-gtm-event=""
                          data-gtm-click-text="ROCKETSPEED on Sustainability"
                        >
                          ROCKETSPEED on Sustainability
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="photo-blade text-dark">
              <div className="image-container">
                <span
                  className="scrim-light-rtl-1 scrim-width-50-1"
                  aria-hidden="true"
                ></span>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    data-srcset="./wp-content/uploads/2022/02/0.0_Every_Journey_Mobile_x2.jpg"
                  />
                  <source
                    media="(max-width: 1024px)"
                    data-srcset="./wp-content/uploads/2022/02/0.0_Every_Journey-1-1024x427.jpg"
                  />
                  <source
                    media="(min-width: 1025px)"
                    data-srcset="./wp-content/uploads/2022/02/0.0_Every_Journey-1-scaled.jpg"
                  />
                  <img
                    data-src="wp-content/uploads/2022/02/0.0_Every_Journey-1-1024x427.jpg"
                    alt=""
                    className="lazyload"
                  />
                </picture>
              </div>
              <div className="container">
                <div className="row align-items-start">
                  <div className="col-sm-10 col-lg-4 offset-lg-8">
                    <div
                      className="content-container"
                      data-gtm-component-title="Jets for every journey"
                    >
                      <h2 className="">Jets for every journey</h2>
                      <div className="body-copy">
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            ROCKETSPEED offers you access to a wide range of
                            aircraft options, from light jets for short trips to
                            large cabin jets for international travel.
                          </span>
                        </p>
                      </div>
                      <div className="cta-container">
                        <a
                          href="jets/index.html"
                          target="_self"
                          className="btn btn-tertiary"
                          data-gtm-click="link_click"
                          data-gtm-event=""
                          data-gtm-click-text="View Aircraft"
                        >
                          View Aircraft
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="promo-with-cropped-circle">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-5 offset-lg-1 ">
                    <div className="image-container image-align-top">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/01/home-cardholder-benefits-800x800.jpg"
                        alt=""
                        className="img-fluid rounded-circle lazyload"
                      />
                    </div>
                  </div>
                  <div
                    className="text-container col-md-6 col-lg-4 offset-lg-1  "
                    data-gtm-component-title="Exclusive Benefits"
                    data-gtm-section-title=""
                  >
                    <h2 className="icon-heading">Exclusive Benefits</h2>

                    <div className="body-copy">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Each year we align with like minded partners to
                          present Card Owners with unique offerings, such as
                          private resort and residence club stays, outdoor
                          adventures, food and wine experiences, health and
                          wellness services, and more.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="logo-garden">
              <div className="container">
                <div className="row">
                  <div className="col" data-gtm-component-title="Our partners">
                    <h2 className="icon-heading">Our partners</h2>
                    <div className="body-copy">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We partner with best-in-class organizations to
                          continually elevate your travel experience.
                        </span>
                      </p>
                    </div>
                    <div className="cta-container"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 logo-col">
                    <div className="logo">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/01/logo-aspen.png"
                        alt=""
                        className="img-fluid lazyload"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 logo-col">
                    <div className="logo">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/02/Logo-Sothebys-1.png"
                        alt=""
                        className="img-fluid lazyload"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 logo-col">
                    <div className="logo">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/01/logo-celts.png"
                        alt=""
                        className="img-fluid lazyload"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 logo-col">
                    <div className="logo">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/01/logo-derby.png"
                        alt=""
                        className="img-fluid lazyload"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 logo-col">
                    <div className="logo">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/01/logo-timbers.png"
                        alt=""
                        className="img-fluid lazyload"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 logo-col">
                    <div className="logo">
                      <img
                        src="#"
                        data-src="https://sentient.com/wp-content/uploads/2022/01/logo-v-found.png"
                        alt=""
                        className="img-fluid lazyload"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="blog-card-deck"
              data-gtm-component-title="Atmosphere"
            >
              <div className="container">
                <div className="row card-heading">
                  <h2 className="">Atmosphere</h2>
                </div>
              </div>
              <div className="container">
                <div className="row blog-cards-box">
                  <div className="col col-lg-4 mb-lg-5">
                    <div
                      className="cards post-2683"
                      data-gtm-section-title="Elite Traveler: ROCKETSPEED Jet to Offer Calculated Footprint to Customers"
                    >
                      <a
                        href="/blog1"
                        aria-label="Read Elite Traveler: ROCKETSPEED Jet to Offer Calculated Footprint to Customers"
                        title="Read Elite Traveler: ROCKETSPEED Jet to Offer Calculated Footprint to Customers"
                        data-gtm-click="link_click"
                        data-gtm-click-text="Elite Traveler: ROCKETSPEED Jet to Offer Calculated Footprint to Customers"
                      >
                        <div className="content-wrapper">
                          <div className="thumbnail">
                            <img
                              src="#"
                              data-src="https://sentient.com/wp-content/uploads/2023/04/ET-840x550-1-768x503.jpg"
                              alt="Elite Traveler: ROCKETSPEED Jet to Offer Calculated Footprint to Customers"
                              className=" lazyload"
                            />
                          </div>
                          <h3 className="blog-deck-title">
                            Elite Traveler: ROCKETSPEED Jet to Offer Calculated
                            Footprint to Customers
                          </h3>
                          <p className="blog-post-excerpt">
                            Leading private aviation company ROCKETSPEED Jet
                            will be the first in history to offer customers a
                            calculated total consumer footprint and offset at no
                            cost to the card holder. Utilizing its own software
                            to provide this information, this is a move that
                            further establishes ROCKETSPEED Jet as&nbsp;a
                            leading force within aviation sustainability.
                          </p>
                        </div>
                        <div className="btn btn-tertiary">
                          Continue reading{" "}
                          <span className="sr-only">
                            Elite Traveler: ROCKETSPEED Jet to Offer Calculated
                            Footprint to Customers
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col col-lg-4 mb-lg-5">
                    <div
                      className="cards post-2678"
                      data-gtm-section-title="Setting New Standards in Sustainability, ROCKETSPEED Jet Leads Industry With First-to-Market Carbon Footprint and Emissions Offset Tracking Initiative"
                    >
                      <a
                        href="/blog2"
                        aria-label="Read Setting New Standards in Sustainability, ROCKETSPEED Jet Leads Industry With First-to-Market Carbon Footprint and Emissions Offset Tracking Initiative"
                        title="Read Setting New Standards in Sustainability, ROCKETSPEED Jet Leads Industry With First-to-Market Carbon Footprint and Emissions Offset Tracking Initiative"
                        data-gtm-click="link_click"
                        data-gtm-click-text="Setting New Standards in Sustainability, ROCKETSPEED Jet Leads Industry With First-to-Market Carbon Footprint and Emissions Offset Tracking Initiative"
                      >
                        <div className="content-wrapper">
                          <div className="thumbnail">
                            <img
                              src="#"
                              data-src="/wp-content/uploads/2023/04/April-Sustainability-2023-768x503.jpg"
                              alt="Setting New Standards in Sustainability, ROCKETSPEED Jet Leads Industry With First-to-Market Carbon Footprint and Emissions Offset Tracking Initiative"
                              className=" lazyload"
                            />
                          </div>
                          <h3 className="blog-deck-title">
                            Setting New Standards in Sustainability, ROCKETSPEED
                            Jet Leads Industry With First-to-Market Carbon
                            Footprint and Emissions Offset Tracking Initiative
                          </h3>
                          <p className="blog-post-excerpt">
                            At no cost to card owners, the industry leader has
                            offset 1,111,745 metric tons of CO2 emissions since
                            the program launched in 2021 with a multi-million
                            dollar investment through its sustainability
                            initiative in partnership with 4AIR.
                          </p>
                        </div>
                        <div className="btn btn-tertiary">
                          Continue reading{" "}
                          <span className="sr-only">
                            Setting New Standards in Sustainability, ROCKETSPEED
                            Jet Leads Industry With First-to-Market Carbon
                            Footprint and Emissions Offset Tracking Initiative
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col col-lg-4 mb-lg-5">
                    <div
                      className="cards post-2637"
                      data-gtm-section-title="With Card Owner Feedback at the Forefront, ROCKETSPEED Jet Rolls Out its Exclusive Benefits Guide Featuring Hand-Selected Partners Offering"
                    >
                      <a
                        href="/blog3"
                        aria-label="Read With Card Owner Feedback at the Forefront, ROCKETSPEED Jet Rolls Out its Exclusive Benefits Guide Featuring Hand-Selected Partners Offering"
                        title="Read With Card Owner Feedback at the Forefront, ROCKETSPEED Jet Rolls Out its Exclusive Benefits Guide Featuring Hand-Selected Partners Offering"
                        data-gtm-click="link_click"
                        data-gtm-click-text="With Card Owner Feedback at the Forefront, ROCKETSPEED Jet Rolls Out its Exclusive Benefits Guide Featuring Hand-Selected Partners Offering"
                      >
                        <div className="content-wrapper">
                          <div className="thumbnail">
                            <img
                              src="#"
                              data-src="https://sentient.com/wp-content/uploads/2023/02/guide-840x550-1-768x503.jpg"
                              alt="With Card Owner Feedback at the Forefront, ROCKETSPEED Jet Rolls Out its Exclusive Benefits Guide Featuring Hand-Selected Partners Offering"
                              className=" lazyload"
                            />
                          </div>
                          <h3 className="blog-deck-title">
                            With Card Owner Feedback at the Forefront,
                            ROCKETSPEED Jet Rolls Out its Exclusive Benefits
                            Guide Featuring Hand-Selected Partners Offering
                          </h3>
                          <p className="blog-post-excerpt">
                            Leading private aviation company and inventor of the
                            jet card category, ROCKETSPEED Jet launches its new
                            2023 Exclusive Benefits Guide for Jet Card Owners
                            created with the understanding that a private flight
                            is just one part of a client’s journey.
                          </p>
                        </div>
                        <div className="btn btn-tertiary">
                          Continue reading{" "}
                          <span className="sr-only">
                            With Card Owner Feedback at the Forefront,
                            ROCKETSPEED Jet Rolls Out its Exclusive Benefits
                            Guide Featuring Hand-Selected Partners Offering
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LandingPage;
