import React from 'react'

const InternationalTravel = () => {
  return (
    <div>
        <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
            <section className="primary-hero primary-hero text-dark">
    <div className="image-container">
          <span className="scrim-light-ttb-1 scrim-height-30-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/international-hero-mobile.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/international-hero-desktop-1024x499.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/international-hero-desktop-scaled.jpg"/>
      <img
        data-src="./wp-content/uploads/2022/02/international-hero-desktop-1024x499.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    <div className="container">
    <div className="row">
            <div className="col-12 col-lg-5">
        <div
          className="content-container"
          data-gtm-component-title="The world at your fingertips"
          data-gtm-section-title=""
        >
          <h1 className="">The world at your fingertips</h1>
          
          <div className="body-copy body-xl col-lg-9 px-0"><p>With our new Large Cabin Jet Card, Cardholders can return to the EU and beyond with peace of mind via guaranteed rates for extended stays, large cabin aircraft, and direct routes for contactless travel.</p>
</div>
                    <div className="cta-container">
            <a href="./private-jet-cards/midsize-large-private-jet-card/index.html" target="_self" className="btn btn-primary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="More about SJ25+ Jet Cards">More about SJ25+ Jet Cards</a>
          </div>
                  </div>
      </div>
    </div>
  </div>
</section>
              <section className="feature-list" data-gtm-component-title="">
  <div className="container">
    <div className="row">
      <div className="col">
        
        
      </div>
    </div>
    <div className="row">
      <div className="col">
        <strong className="">Features include:</strong>
      </div>
    </div>
        <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="wp-content/uploads/2022/02/icon-transatlantic.svg" alt="" className=" lazyload"/>
              <figcaption className="">Transatlantic routes with dynamic, competitive pricing</figcaption>
            </figure>
          </div>
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="wp-content/uploads/2022/02/icon-dollar.svg" alt="" className=" lazyload"/>
              <figcaption className="">Extended, guaranteed access to popular EU destinations like Paris, Zurich, Milan and Frankfurt</figcaption>
            </figure>
          </div>
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="wp-content/uploads/2022/02/icon-eu.svg" alt="" className=" lazyload"/>
              <figcaption className="">Access to guaranteed, fixed rates whether traveling domestically within the U.S., abroad to Europe and beyond</figcaption>
            </figure>
          </div>
                  </div>
             <div className="row">
        <div className="col">
          <div className="cta-container">
            <a href="./private-jet-cards/midsize-large-private-jet-card/index.html" target="_self" className="btn btn-tertiary btn-lg" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="More about SJ25+ Jet Cards">More about SJ25+ Jet Cards</a>
          </div>
        </div>
      </div>
            </div>
</section>
          <section className="promo-with-unbounded-photo align-right">
    <div className="image-container">
    <img src="#" data-src="wp-content/uploads/2022/02/map-1024x585.jpg" alt="" className="img-fluid lazyload"/>
  </div>
    <div className="container">
    <div className="row">
      <div
        className="col-md-4 offset-md-7"
        data-gtm-component-title="Launch into Europe from London"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Launch into Europe from London</h2>
        
        <div className="body-lg"><p>Once you’ve landed in London, ROCKETSPEED Jet gives you easy access to international territories, guaranteed with 72 hours’ notice (within zones).</p>
</div>
              </div>
    </div>
  </div>
</section>
          <section className="cta-blade text-dark">
          <div className="image-container">
                        <picture>
          <source media="(max-width: 768px)" srcset="./wp-content/uploads/2022/02/international-cta-768x192.jpg"/>
          <source media="(max-width: 1024px)" srcset="./wp-content/uploads/2022/02/international-cta-1024x256.jpg"/>
          <source media="(min-width: 1025px)" srcset="./wp-content/uploads/2022/02/international-cta-scaled.jpg"/>
          <img
            src="./wp-content/uploads/2022/02/international-cta-1024x256.jpg"
            alt=""/>
        </picture>
      </div>
    
    <div className="container">
      <div className="row">
        
        <div className="col-11 col-lg-6">
          <div className="content-container" data-gtm-component-title="Go farther with ROCKETSPEED Jet">
            <h2 className="">Go farther with ROCKETSPEED Jet</h2>
                          <div className="cta-container">
                <a href="./learn-more/index.html" target="_self" className="btn btn-outline-secondary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Get Started">Get Started</a>
              </div>
                      </div>
        </div>
      </div>
    </div>
  </section>
              </main>
              </div>
    </div>
    </div>
  )
}

export default InternationalTravel