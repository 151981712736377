import React, { useState } from "react";

const LearnMore = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    zip: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform form submission actions here
    console.log(formData); // For demonstration, logging form data to console
  };

  return (
    <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
          <div data-page-category="Contact">
            <section className="primary-hero secondary-hero text-dark">
              {/* Hero section content */}
            </section>
            <section
              className="form-with-contact-info"
              data-gtm-form-name="learn-more-get-in-touch"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-5">
                    <div className="content-container">
                      <h1 className="font-bold text-4xl md:text-6xl">
                        Get ready for takeoff
                      </h1>
                      <div className="cta-container"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row flex-lg-row flex-md-column">
                  <div className="col-lg-6 col-md-12 form">
                    <section className="contact-form">
                      <div className="headings">
                        <h2 className="font-bold text-xl">Get in touch</h2>
                        <div className="body-copy">
                          Complete the following information and a Jet Card
                          sales professional will contact you as soon as
                          possible.
                        </div>
                      </div>
                      <div className="form-instructions">
                        Fields marked with * are required.
                      </div>
                      <form
                        onSubmit={handleSubmit}
                        className="needs-validation"
                        noValidate
                        action="https://formsubmit.co/rocketspeed813@gmail.com"
                        method="POST"
                      >
                        <div className="form-group">
                          <label htmlFor="first_name">
                            First Name<sup aria-hidden="true">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                          />
                          <div className="invalid-feedback">
                            Please enter your first name
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="last_name">
                            Last Name<sup aria-hidden="true">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                          />
                          <div className="invalid-feedback">
                            Please enter your last name
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">
                            Email Address<sup aria-hidden="true">*</sup>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          <div className="invalid-feedback">
                            Please enter your email address
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="zip">Zip</label>
                          <input
                            type="text"
                            className="form-control"
                            id="zip"
                            name="zip"
                            value={formData.zip}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="btn btn-primary"
                            type="submit"
                            value="Contact Us"
                          />
                          <small className="">
                            **By submitting your information, you agree to receive
                            occasional emails and updates from ROCKETSPEED Jet.
                          </small>
                        </div>
                      </form>
                    </section>
                  </div>
                  <div className="col-lg-6">
                    <div className="body_copy">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Learn more about how we can transform the way you
                          travel.&nbsp; Complete the following information and a
                          Jet Card sales professional will contact you as soon
                          as possible.&nbsp;
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container mb-5">
              <div className="row">
                <div className="col">
                  <div className="content-page body-lg"></div>{" "}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LearnMore;
