const PrivateJetCards = () => {
  return (
    <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
          <section className="primary-hero  text-dark">
            <div className="image-container">
              <span
                className="scrim-light-ttb-1 scrim-height-30-1"
                aria-hidden="true"
              />
              <picture>
                <source
                  media="(max-width: 768px)"
                  data-srcset="/wp-content/uploads/2022/02/2.1_How_The_Sentient_Jet-Card_Hero_Mobile_x2-1.jpg"
                  srcSet="/wp-content/uploads/2022/02/2.1_How_The_Sentient_Jet-Card_Hero_Mobile_x2-1.jpg"
                />
                <source
                  media="(max-width: 1024px)"
                  data-srcset="/wp-content/uploads/2022/01/jet-card-landing-bg-desktop-1024x499.jpg"
                  srcSet="/wp-content/uploads/2022/01/jet-card-landing-bg-desktop-1024x499.jpg"
                />
                <source
                  media="(min-width: 1025px)"
                  data-srcset="/wp-content/uploads/2022/01/jet-card-landing-bg-desktop.jpg"
                  srcSet="/wp-content/uploads/2022/01/jet-card-landing-bg-desktop.jpg"
                />
                <img
                  data-src="/wp-content/uploads/2022/01/jet-card-landing-bg-desktop-1024x499.jpg"
                  alt=""
                  className=" lazyloaded"
                  src="/wp-content/uploads/2022/01/jet-card-landing-bg-desktop-1024x499.jpg"
                />
              </picture>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div
                    className="content-container"
                    data-gtm-component-title="A private jet whenever and wherever you need it—guaranteed"
                    data-gtm-section-title=""
                  >
                    <h1 className="">
                      A private jet whenever and wherever you need it—guaranteed
                    </h1>
                    <div className="body-copy body-xl col-lg-9 px-0">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Offering the most thoughtful choice in private
                          aviation, Sentient Jet Cards offer all the
                          convenience, practicality, and ease that comes
                          standard with private air travel, without the hassle,
                          maintenance and high costs of owning a jet.
                        </span>
                      </p>
                    </div>
                    <div className="cta-container">
                      <a
                        href="/learn-more"
                        target="_self"
                        className="btn btn-primary"
                        data-gtm-click="link_click"
                        data-gtm-event=""
                        data-gtm-click-text="Get your Jet Card"
                      >
                        Get your Jet Card
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="comparison-table"
            data-gtm-component-title="Flexibility takes flight"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <h2 className="icon-heading">Flexibility takes flight</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9">
                  <div className="comparison-table-body">
                    With two options to choose from, a Sentient Jet Card gives
                    you the flexibility of choosing the aircraft size that best
                    fits your private jet travel needs. Both Jet Card options
                    provide you access to fixed rates, seamless booking, flight
                    hours that never expire, and more.
                  </div>
                </div>
              </div>
            </div>
            <div className="lower">
              <div className="container">
                <div className="row flex-lg-row flex-md-column">
                  <div
                    className="col-md col-lg-5"
                    data-gtm-section-title="SJ25"
                  >
                    <div className="card-wrapper">
                      <div className="image-container">
                        <img
                          src="/wp-content/uploads/2022/01/Sentient-Jet-SJ25-Card-1.png"
                          data-src="/wp-content/uploads/2022/01/Sentient-Jet-SJ25-Card-1.png"
                          alt=""
                          className="img-fluid card-image elevation-float ls-is-cached lazyloaded"
                        />
                      </div>
                      <div className="card-content">
                        <div className="row">
                          <div className="col">
                            <h3 className="h2">SJ25</h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md col-lg-8">
                            <div className="card-text">
                              <strong className="">
                                Flexible access to light aircraft
                              </strong>
                              <div className="comparison-card-body">
                                The SJ25 Jet Card includes 25 hours of flight
                                time, combining flexibility with outstanding
                                quality and value for Light Jet flyers.
                                <p className="body-xs pt-2" />
                                <p style={{ fontSize: 13 }}>
                                  Fuel Surcharge and Federal Excise Tax will
                                  apply.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md col-lg-4">
                            {/* <div className="pricing-detail-container">
                              <div className="pricing-cell ">
                                <div>
                                  <div className="">Base 25-Hour Price</div>
                                  $174,375
                                  <div className="pricing-note">
                                    <small></small>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div className="pricing-detail-container">
                              <div className="pricing-cell extra-padding">
                                <div>
                                  <div className="">Base Hourly Rate</div>
                                  $6,975
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col cta-container">
                            <a
                              href="/private-jet-cards/light-private-jet-card/"
                              target="_self"
                              className="btn btn-outline-secondary btn-md"
                              data-gtm-click="link_click"
                              data-gtm-event=""
                              data-gtm-click-text="More about SJ25 Jet Card"
                            >
                              More about SJ25 Jet Card
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md col-lg-5"
                    data-gtm-section-title="SJ25+"
                  >
                    <div className="card-wrapper">
                      <div className="image-container">
                        <img
                          src=""
                          data-src="/wp-content/uploads/2022/01/Sentient-Jet-SJ25-Card.png"
                          alt=""
                          className="img-fluid card-image elevation-float lazyload"
                        />
                      </div>
                      <div className="card-content">
                        <div className="row">
                          <div className="col">
                            <h3 className="h2">SJ25+</h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md col-lg-8">
                            <div className="card-text">
                              <strong className="">
                                For unmatched convenience
                              </strong>
                              <div className="comparison-card-body">
                                The SJ25+ Jet Card offers access to the most
                                popular, Wi-Fi enabled Mid, Super-Mid and Large
                                Cabin jets available today.
                                <p className="body-xs pt-2" />
                                <p style={{ fontSize: 13 }}>
                                  Fuel Surcharge and Federal Excise Tax will
                                  apply.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md col-lg-4">
                            {/* <div className="pricing-detail-container">
                              <div className="pricing-cell extra-padding">
                                <div>
                                  <div className="">
                                    Base 25-Hour Price starting at
                                  </div>
                                  $224,625
                                </div>
                              </div>
                            </div> */}
                            <div className="pricing-detail-container">
                              <div className="pricing-cell extra-padding">
                                <div>
                                  <div className="">
                                    Base Hourly Rate starting at
                                  </div>
                                  $8,985
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col cta-container">
                            <a
                              href="/private-jet-cards/midsize-large-private-jet-card/"
                              target="_self"
                              className="btn btn-outline-secondary btn-md"
                              data-gtm-click="link_click"
                              data-gtm-event=""
                              data-gtm-click-text="More about SJ25+ Jet Card"
                            >
                              More about SJ25+ Jet Card
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cta-blade text-light">
            <div className="image-container">
              <picture>
                <source
                  media="(max-width: 768px)"
                  srcSet="/wp-content/uploads/2022/02/partnering-with-4Air.jpg"
                />
                <source
                  media="(max-width: 1024px)"
                  srcSet="/wp-content/uploads/2022/02/jet-card-cta-1024x256.jpg"
                />
                <source
                  media="(min-width: 1025px)"
                  srcSet="/wp-content/uploads/2022/02/jet-card-cta.jpg"
                />
                <img
                  src="/wp-content/uploads/2022/02/jet-card-cta-1024x256.jpg"
                  alt=""
                />
              </picture>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-11 col-lg-6">
                  <div
                    className="content-container"
                    data-gtm-component-title="Can’t decide what card is right for you? We can help."
                  >
                    <h2 className="">
                      Can’t decide what card is right for you? We can help.
                    </h2>
                    <div className="cta-container">
                      <a
                        href="/learn-more"
                        target="_self"
                        className="btn btn-outline-secondary"
                        data-gtm-click="link_click"
                        data-gtm-event=""
                        data-gtm-click-text="Get In Touch"
                      >
                        Get In Touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PrivateJetCards;
