import React from 'react'

const DataSafety = () => {
  return (
    <div>
            <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
            <section className="primary-hero primary-hero text-dark">
    <div className="image-container">
          <span className="scrim-light-ttb-1 scrim-height-30-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/3.3_Safety_Data_Hero_Mobile_2x.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/data-safety-primary-hero-desktop-1024x499.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/data-safety-primary-hero-desktop-scaled.jpg"/>
      <img
        data-src="../wp-content/uploads/2022/02/data-safety-primary-hero-desktop-1024x499.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    <div className="container">
    <div className="row">
            <div className="col-12 col-lg-5">
        <div
          className="content-container"
          data-gtm-component-title="We use data to keep you safe"
          data-gtm-section-title=""
        >
          <h1 className="">We use data to keep you safe</h1>
          
          <div className="body-copy body-xl col-lg-9 px-0"><p><span style={{fontWeight: 400}}>For 25 years, ROCKETSPEED Jet has aggregated data from thousands of flights arranged for its more than 8,000 Card Owners and, from that insight, created a proprietary software system and enterprise-wide service culture that anticipates the hundreds of possible variables for every flight in order to deliver the most consistent and precise customer experience.</span></p>
</div>
                    <div className="cta-container">
            
          </div>
                  </div>
      </div>
    </div>
  </div>
</section>
              <section className="promo-with-cropped-circle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-lg-5 offset-lg-1 order-md-2">
        <div className="image-container image-align-top">
                    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/data-safety-circle-800x800.jpg" alt="" className="img-fluid rounded-circle lazyload"/>
        </div>
      </div>
      <div
        className="text-container col-md-6 col-lg-4 offset-lg-1 order-md-1 "
        data-gtm-component-title="How does ROCKETSPEED protect personal information?"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">How does ROCKETSPEED protect personal information?</h2>
        
        <div className="body-copy"><p><span style={{fontWeight: 400}}>We maintain a security program intended to keep the personal information stored in our systems protected from unauthorized access and misuse. Our systems are configured with data encryption, or scrambling technologies and firewalls constructed to industry standards. We also use industry standard data encryption methods that protect the data you send over the Internet. </span></p>
</div>
        <div className="cta-container">
      <a href="../legal/index.html" target="_self" className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Learn more about how we use data in our privacy policy">Learn more about how we use data in our privacy policy</a>
  </div>
      </div>
    </div>
  </div>
</section>
          <section className="cta-blade text-dark">
          <div className="image-container">
                        <picture>
          <source media="(max-width: 768px)" srcset="./wp-content/uploads/2022/02/3.2_CTA_Jet_Rear_Mobile_2x.jpg"/>
          <source media="(max-width: 1024px)" srcset="./wp-content/uploads/2022/02/data-safety-cta-1024x256.jpg"/>
          <source media="(min-width: 1025px)" srcset="./wp-content/uploads/2022/02/data-safety-cta.jpg"/>
          <img
            src="../wp-content/uploads/2022/02/data-safety-cta-1024x256.jpg"
            alt=""/>
        </picture>
      </div>
    
    <div className="container">
      <div className="row">
        
        <div className="col-11 col-lg-6">
          <div className="content-container" data-gtm-component-title="Ready to fly?">
            <h2 className="">Ready to fly?</h2>
                          <div className="cta-container">
                <a href="../learn-more/index.html" target="_self" className="btn btn-outline-secondary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Get in Touch">Get in Touch</a>
              </div>
                      </div>
        </div>
      </div>
    </div>
  </section>
              </main>
              </div>
    </div>

    </div>
  )
}

export default DataSafety