import React from "react";

const Blog2 = () => {
  return (
    <div>
      <div class="wrap" role="document">
        <div class="content">
          <main class="main" id="main">
            <article class="single-blog post-2683 post type-post status-publish format-standard has-post-thumbnail hentry category-news">
              <header>
                <div class="image-container">
                  <picture>
                    <source
                      media="(max-width: 768px)"
                      data-srcset="./wp-content/uploads/2023/04/ET-840x550-1-768x503.jpg"
                    />
                    <source
                      media="(min-width: 1025px)"
                      data-srcset="./wp-content/uploads/2023/04/ET-840x550-1.jpg"
                    />
                    <img
                      data-src="./wp-content/uploads/2023/04/ET-840x550-1.jpg"
                      alt=""
                      class="lazyload"
                    />
                  </picture>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-lg-5 offset-lg-7">
                      <h1 class="entry-title h2">
                        Elite Traveler: ROCKETSPEED Jet to Offer Calculated
                        Footprint to Customers
                      </h1>
                      <time
                        class="updated"
                        datetime="2023-04-21T19:18:22+00:00"
                      >
                        April 21, 2023
                      </time>
                    </div>
                  </div>
                </div>
              </header>
              <div class="container">
                <div class="row">
                  <div class="col-md-10 offset-md-1">
                    <div class="entry-content">
                      <p>
                        Leading private aviation company ROCKETSPEED Jet will be
                        the first in history to offer customers a calculated
                        total consumer footprint and offset at no cost to the
                        card holder.
                      </p>

                      <p>
                        Utilizing its own software to provide this information,
                        this is a move that further establishes ROCKETSPEED Jet
                        as&nbsp;
                        <a
                          href="https://elitetraveler.com/cars-jets-and-yachts/sentient-jet-offset-program"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          a leading force within aviation sustainability
                        </a>
                        . The company has already offset over one million metric
                        tons of CO2 emissions since ROCKETSPEED Jet began its
                        sustainability initiative in partnership with 4AIR,
                        first launched in 2021.
                      </p>

                      <p>
                        Continue reading on{" "}
                        <a
                          href="https://elitetraveler.com/cars-jets-and-yachts/sentient-jet-calculated-footprint"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Elite Traveler
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <footer class="row">
                  <div class="col text-center my-3">
                    <a
                      href="/"
                      class="btn btn-secondary"
                      data-gtm-click="link_click"
                    >
                      Back to Atmosphere
                    </a>
                  </div>
                </footer>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Blog2;
