import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
const testimonies = [
  {
    id: 1,
    heading: "Bitcoin Payment",
    text: "The crew was amazing and the flight was extremely smooth but the process in confirming the bank transaction after I made payment was disappointing really stressful due to some incompetent banking system but the new Bitcoin payment method is far much preferable smooth and fast Banks are really annoying",
    author: "Mark Jeffery",
    // position: "Accountant",
    image:
      "https://hespokestyle.com/wp-content/uploads/2017/03/houndstooth-blazer-navy-pants-denim-cutaway-collar-shirt-green-medalion-print-tie-spring-business-casual-outfit-ideas-men-walnut-leather-shoes-allen-edmonds-strand-1-664x752.jpg",
  },
  {
    id: 2,
    heading: "Fabulous service in an emergency !",
    text: "This was a last-minute charter due to a health issue. Our broker Zak could not have been more helpful or patient, and the pilots and flight were great!",
    author: "Laurel Yingluck",
    // position: "Product Manager",
    image:
      "https://cdn.britannica.com/20/222720-050-08108BFB/Thailand-Prime-Minister-Yingluck-Shinawatra-2012.jpg",
  },
  {
    id: 3,
    heading: "Great experience, newer plane and competent pilots",
    text: "Rocketspeed did a wonderful job coordinating our trip and handling our last minute changes. We will be reaching out to Rocketspeed on our next charter opportunity.",
    author: "Chearavanont Denis",
    // position: "Data Analyst",
    image:
      "https://images.prestigeonline.com/wp-content/uploads/sites/8/2023/03/13215808/charoen-sirivadhanabhakdi.jpg",
  },
  {
    id: 4,
    heading: "The response time and scheduling of the flight was fantastic",
    text: "I am so grateful to Rocketspeed Jets for making our cross country move so seamless. One of our daughters has autism and cannot fly commercial. The Rocketspeed Jets pilots made us feel so welcome, comfortable and safe. Much thanks to Chanel Chong who worked diligently to get this all set up for us. She was so informative and helpful and even arranged catering and answered my many questions. I highly recommend Rocketspeed Jets.",
    author: "Paitoon Phichai",
    // position: "Accountant",
    image:
      "https://static.bangkokpost.com/media/content/20220708/4361907.jpg",
  },
  // Add more testimonies to the array
];

const TestimonialSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimony = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonies.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimony = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonies.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextTestimony,
    onSwipedRight: prevTestimony,
  });

  const currentTestimony = testimonies[currentIndex];

  return (
    <section className=" mx-1 mb-4 pt-10 lg:pt-20  bg-[#f8f8fa]">
      <div className="container px-6 py-16 mx-auto">
        <div className=" mb-2 md:flex md:items-center md:justify-between">
          <div>
            <div
              className=" bg-primary p-2 rounded-full text-center text-white"
              style={{ width: 100 }}
            >
              reviews &nbsp;
            </div>
            <h1 className="text-2xl font-semibold text-gray-800  lg:text-3xl">
              <span className="font-extrabold text-primary">
                Positive reviews
              </span>{" "}
              of our clients
            </h1>

            <div className="flex mx-auto mt-6">
              <span className="inline-block w-40 h-1 bg-primary rounded-full"></span>
              <span className="inline-block w-3 h-1 mx-1 bg-primary rounded-full"></span>
              <span className="inline-block w-1 h-1 bg-primary rounded-full"></span>
            </div>
          </div>
        </div>
        <div className="lg:-mx-6 lg:flex lg:items-center">
          <div className="lg:w-1/2 lg:mx-6 w-full h-96 rounded-3xl lg:h-[36rem]">
            <img
              className="object-cover object-top w-full h-full rounded-3xl"
              src={currentTestimony.image}
              loading="lazy"
              alt=""
            />
          </div>
          <div className="mt-8 lg:w-1/2 lg:px-6 lg:mt-0" {...handlers}>
            <p className="text-5xl font-semibold text-primary ">&quot;</p>
            <h1 className="text-2xl font-semibold text-gray-800 dark:text-black lg:text-3xl lg:w-96">
              {currentTestimony.heading}
            </h1>
            <p className="max-w-lg mt-6 text-gray-500 dark:text-gray-500 ">
              {currentTestimony.text}
            </p>
            <h3 className="mt-6 text-lg font-medium text-primary">
              {currentTestimony.author}
            </h3>
            {/* <p className="text-gray-600 dark:text-gray-700">
              {currentTestimony.position}
            </p> */}
            <div className="flex items-center justify-between mt-12 lg:justify-start">
              <button
                title="left arrow"
                className="p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-400 dark:hover-bg-gray-800 hover:bg-gray-100"
                onClick={prevTestimony}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                title="right arrow"
                className="p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-400 dark:hover-bg-gray-800 lg:mx-6 hover-bg-gray-100"
                onClick={nextTestimony}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
