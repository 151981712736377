import React from 'react'

const Sustainability = () => {
  return (
    <div>
          <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
            <section className="primary-hero primary-hero text-dark">
    <div className="image-container">
          <span className="scrim-light-ttb-1 scrim-height-30-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Hero_Mobile_x2.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Hero-1024x499.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Hero.jpg"/>
      <img
        data-src="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Hero-1024x499.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    <div className="container">
    <div className="row">
            <div className="col-12 col-lg-5">
        <div
          className="content-container"
          data-gtm-component-title="Breathe easy with Sentient"
          data-gtm-section-title=""
        >
          <h1 className="">Breathe easy with Sentient</h1>
          
          <div className="body-copy body-xl col-lg-9 px-0"><p><span style={{fontWeight: 400}}>Since the beginning of 2021, every flight flown by ROCKETSPEED Jet Card Owners is offset to be both carbon and emissions neutral, and at zero cost to our clients.</span></p>
</div>
                    <div className="cta-container">
            
          </div>
                  </div>
      </div>
    </div>
  </div>
</section>
              <section className="promo-with-cropped-circle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-lg-5 offset-lg-1 order-md-2">
        <div className="image-container image-align-top">
                    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.2_Why_Sustainability_Commitment-800x800.jpg" alt="" className="img-fluid rounded-circle lazyload"/>
        </div>
      </div>
      <div
        className="text-container col-md-6 col-lg-4 offset-lg-1 order-md-1 "
        data-gtm-component-title="Our commitment to sustainability"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Our commitment to sustainability</h2>
        
        <div className="body-copy"><p><span style={{fontWeight: 400}}>ROCKETSPEED funds offset projects like forestry and renewable energy, that reduce emissions equivalent to the emissions from flying.</span></p>
</div>
        <div className="cta-container">
      <a href="./wp-content/uploads/2022/03/Sentient-Jet-ClimateCommitment-Sustainability.pdf" target="_self" className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Learn more about our commitment">Learn more about our commitment</a>
  </div>
      </div>
    </div>
  </div>
</section>
          <section className="promo-with-unbounded-photo align-right">
    <div className="image-container">
    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.2_Why_Sustainability_Emissions-1024x585.jpg" alt="" className="img-fluid lazyload"/>
  </div>
    <div className="container">
    <div className="row">
      <div
        className="col-md-4 offset-md-7"
        data-gtm-component-title="Emissions neutral - a step beyond carbon neutral"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Emissions neutral - a step beyond carbon neutral</h2>
        
        <div className="body-lg"><p><span style={{fontWeight: 400}}>CO2 emissions only account for one-third of aviation’s environmental impact. ROCKETSPEED Jet’s sustainability program is offsetting all flights 300% to cover other aviation emissions—including water vapor, aerosols, and nitrous oxide—which account for the other two-thirds of emissions produced when flying.</span></p>
</div>
              </div>
    </div>
  </div>
</section>
          <section className="promo-with-cropped-circle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-lg-5 offset-lg-1 order-md-2">
        <div className="image-container image-align-bottom">
                    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.2_Why_Sustainability_4Air-800x800.jpg" alt="" className="img-fluid rounded-circle lazyload"/>
        </div>
      </div>
      <div
        className="text-container col-md-6 col-lg-4 offset-lg-1 order-md-1 "
        data-gtm-component-title="Partnering with 4AIR "
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Partnering with 4AIR </h2>
        
        <div className="body-copy"><p><span style={{fontWeight: 400}}>ROCKETSPEED Jet has teamed with environmental leader 4AIR to review our flight volumes and shared impact in the skies, as well as to help us develop options to drive our new sustainability initiative beyond industry goals.</span></p>
</div>
              </div>
    </div>
  </div>
</section>
          <section className="photo-blade text-dark">
    <div className="image-container">
        <span className="scrim-light-ttb-1 scrim-height-50-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Dowload_mobile_2x.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Downloads-1-1024x427.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Downloads-1.jpg"/>
      <img
        data-src="./wp-content/uploads/2022/02/1.2_Why_Sustainability_Downloads-1-1024x427.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    <div className="container">
    <div className="row align-items-center">
      <div className="col-sm-10 col-lg-4 offset-lg-8">
        <div className="content-container" data-gtm-component-title="Learn more about our sustainable initiatives">
          <h2 className="">Learn more about our sustainable initiatives</h2>
          
          <div className="cta-container">
      <a href="../wp-content/uploads/2022/03/Sentient-Jet-ClimateCommitment-Sustainability.pdf" target="_blank" className="btn btn-tertiary btn-with-download" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Download the brochure">Download the brochure</a>
  </div>
        </div>
      </div>
    </div>
  </div>
</section>
              </main>
              </div>
    </div>
    </div>
  )
}

export default Sustainability