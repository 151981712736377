import React from 'react'

const ScientistCertified = () => {
  return (
    <div>
         <div class="wrap" role="document">
      <div class="content">
        <main class="main" id="main">
            <section class="primary-hero primary-hero text-dark">
    <div class="image-container">
          <span class="scrim-light-ttb-1 scrim-height-30-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/Sentient_Certified_Hero_mobile.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/Sentient_Certified_Hero_desktop-1024x499.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/Sentient_Certified_Hero_desktop-scaled.jpg"/>
      <img
        data-src="../wp-content/uploads/2022/02/Sentient_Certified_Hero_desktop-1024x499.jpg"
        alt=""
        class="lazyload"/>
    </picture>
  </div>
    <div class="container">
    <div class="row">
            <div class="col-12 col-lg-5">
        <div
          class="content-container"
          data-gtm-component-title="ROCKETSPEED Certifications lead the aviation industry"
          data-gtm-section-title=""
        >
          <h1 class="">ROCKETSPEED Certifications lead the aviation industry</h1>
          
          <div class="body-copy body-xl col-lg-9 px-0"><p><span style={{fontWeight: 400}}>Safety is not just our promise; it’s at the core of everything we do. We’ve invested millions of dollars in our service and safety infrastructure, and from our industry first Independent Safety Advisory Board to our exclusive safety and flight certification technology, we are fully committed to the highest level of safety.</span></p>
</div>
                    <div class="cta-container">
            
          </div>
                  </div>
      </div>
    </div>
  </div>
</section>
              <section class="two-up-promo">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h2 class="icon-heading">ROCKETSPEED Certified®</h2>
        <div class="body-lg"><p><span style={{fontWeight: 400}}>A crucial element of our safety program is a rigorous, proprietary certification process called ROCKETSPEED Certified. Led by our dedicated in-house Chief Safety Officer, each operator, aircraft, and pilot that flies for our program, as well as each flight, is individually certified by Sentient, ensuring that all components of our stringent safety standards are met on each and every trip.</span></p>
</div>
      </div>
    </div>
    <div class="row">
            <div class="col-md-6">
        <div class="promo">
          <div class="image-container background-color-primary-50">
            <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/Operator_Certification-1024x402.png" alt="" class="img-fluid lazyload"/>
          </div>
          <h3 class="">Operator certification</h3>
          <div class="body-lg"><p><span style={{fontWeight: 400}}>ROCKETSPEED arranges more flights than any other charter procurement business in the country, which enables us to develop and maintain preferred relationships with premier operators. Of note, fewer than 35% of the more than 550 charter jet operators in the United States have passed the certification process to fly for ROCKETSPEED clients.</span></p>
</div>
        </div>
      </div>
            <div class="col-md-6">
        <div class="promo">
          <div class="image-container background-color-primary-50">
            <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/Aircraft_Certification-1024x402.png" alt="" class="img-fluid lazyload"/>
          </div>
          <h3 class="">Aircraft certification</h3>
          <div class="body-lg"><p><span style={{fontWeight: 400}}>To become ROCKETSPEED Certified, aircraft are individually reviewed based on their history–including maintenance reliability–and also must pass a physical inspection that covers key safety elements in addition to the comfort and condition of the aircraft.</span></p>
</div>
        </div>
      </div>
            <div class="col-md-6">
        <div class="promo">
          <div class="image-container background-color-primary-50">
            <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/Pilot_Certification-1024x402.png" alt="" class="img-fluid lazyload"/>
          </div>
          <h3 class="">Pilot certification</h3>
          <div class="body-lg"><p><span style={{fontWeight: 400}}>All of our operators’ crew members are individually certified by Sentient. Pilots must uphold a high level of safety standard, exceeding FAR Part 135 regulations in a host of categories.</span></p>
</div>
        </div>
      </div>
            <div class="col-md-6">
        <div class="promo">
          <div class="image-container background-color-primary-50">
            <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/Flight_Certification-1024x402.png" alt="" class="img-fluid lazyload"/>
          </div>
          <h3 class="">Flight certification</h3>
          <div class="body-lg"><p><span style={{fontWeight: 400}}>Once a trip is booked, the flight is also ROCKETSPEED Certified, with up-to-date information on the assigned operator, aircraft and crew verified against our database to ensure all safety standards are met.</span></p>
</div>
        </div>
      </div>
          </div>
  </div>
</section>
          <section class="cta-blade text-dark">
          <div class="image-container">
                        <picture>
          <source media="(max-width: 768px)" srcset="./wp-content/uploads/2022/02/certified-cta-mobile.jpg"/>
          <source media="(max-width: 1024px)" srcset="./wp-content/uploads/2022/02/certified-cta-desktop-1024x256.jpg"/>
          <source media="(min-width: 1025px)" srcset="./wp-content/uploads/2022/02/certified-cta-desktop.jpg"/>
          <img
            src="../wp-content/uploads/2022/02/certified-cta-desktop-1024x256.jpg"
            alt=""/>
        </picture>
      </div>
    
    <div class="container">
      <div class="row">
        
        <div class="col-11 col-lg-6">
          <div class="content-container" data-gtm-component-title="Ready to fly with us?">
            <h2 class="">Ready to fly with us?</h2>
                          <div class="cta-container">
                <a href="../learn-more/index.html" target="_self" class="btn btn-outline-secondary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Get started">Get started</a>
              </div>
                      </div>
        </div>
      </div>
    </div>
  </section>
              </main>
              </div>
    </div>
    </div>
  )
}

export default ScientistCertified