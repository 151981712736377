import LandingPage from "./components/Landingpage";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Experience from "./components/Experience";
import Technology from "./components/Technology";
import Sustainability from "./components/Sustainability";
import InternationalTravel from "./components/InternationalTravel";
import DataSafety from "./components/DataSafety";
import Covid from "./components/Covid";
import Footer from "./components/Footer";
import ScientistCertified from "./components/ScientistCertified";
import Cookie from "./components/Cookie";
import Blog1 from "./components/Blog1";
import Blog2 from "./components/Blog2";
import Blog3 from "./components/Blog3";
import TestimonialSection from "./components/Testimonials";
import PrivateJetCards from "./components/PrivateJetCards";
import LearnMore from "./components/LearnMore";

function App() {
  return (
    <body className="home page-template page-template-template-home template-home page page-id-6 app-data index-data singular-data page-data page-6-data page-home-data template-home-data front-page-data">
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-BJ2W"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      <a href="#main" class="skip-to-main-content">
        Skip to main content
      </a>
      <Header />

      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/covid" element={<Covid />} />
          <Route path="/private-jet-cards" element={<PrivateJetCards />} />
          <Route path="/datasafety" element={<DataSafety />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/learn-more" element={<LearnMore />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/certified" element={<ScientistCertified />} />
          <Route path="/cookie" element={<Cookie />} />
          <Route path="/testimonies" element={<TestimonialSection />} />
          <Route
            path="/international-travel"
            element={<InternationalTravel />}
          />
          <Route path="/blog1" element={<Blog1 />} />
          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
        </Routes>
      </Router>
      <Footer />
    </body>
  );
}

export default App;
