import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="content-info">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <span className="brand">
                <img
                  src="wp-content/themes/Sentient-2022/dist/images/sentientjet-logo_ff2cc4e9.png"
                  alt="ROCKETSPEED Jet"
                />
              </span>
            </div>
            <div className="col-md-9 text-right">
              {/* <nav className="footer-utility btn-toolbar offset-md-8 col-md-4 px-0" aria-label="Footer Utility">
              <div className="menu-footer-utility-container"><ul id="menu-footer-utility" className="nav"><li id="menu-item-1177" className="nav-btn nav-btn-primary menu-item menu-item-type-post_type menu-item-object-page menu-item-1177"><a href="learn-more/index.html">Learn More</a></li>
  <li id="menu-item-242" className="nav-btn nav-btn-secondary menu-item menu-item-type-custom menu-item-object-custom menu-item-242"><a href="#">Login</a></li>
  </ul></div>
      </nav> */}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <nav className="footer-primary" aria-label="Footer Primary">
                <div className="menu-footer-primary-container">
                  <ul id="menu-footer-primary" className="nav row">
                    <li
                      id="menu-item-244"
                      className="nav-column menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-244"
                    >
                      <a>Why ROCKETSPEED</a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-1946"
                          className="nav-column-item menu-item menu-item-type-post_type menu-item-object-page menu-item-1946"
                        >
                          <a href="/experience">The ROCKETSPEED Experience</a>
                        </li>
                        <li
                          id="menu-item-1947"
                          className="nav-column-item menu-item menu-item-type-post_type menu-item-object-page menu-item-1947"
                        >
                          <a href="/sustainability">Sustainability</a>
                        </li>
                        {/* <li
                          id="menu-item-1171"
                          className="nav-column-item menu-item menu-item-type-post_type menu-item-object-page menu-item-1171"
                        >
                          <a href="/technology">Technology</a>
                        </li> */}
                      </ul>
                    </li>
                    <li
                      id="menu-item-245"
                      className="nav-column menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-245"
                    >
                      <a>How it Works</a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-1945"
                          className="nav-column-item menu-item menu-item-type-post_type menu-item-object-page menu-item-1945"
                        >
                          <a href="/international-travel">
                            International Private Jet Travel
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-246"
                      className="nav-column menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-246"
                    >
                      <a>Safety</a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-1899"
                          className="nav-column-item menu-item menu-item-type-post_type menu-item-object-page menu-item-1899"
                        >
                          <a href="/certified">ROCKETSPEED Certified®</a>
                        </li>
                        <li
                          id="menu-item-1948"
                          className="nav-column-item menu-item menu-item-type-post_type menu-item-object-page menu-item-1948"
                        >
                          <a href="/datasafety">Data Safety</a>
                        </li>
                        <li
                          id="menu-item-1921"
                          className="nav-column-item menu-item menu-item-type-post_type menu-item-object-page menu-item-1921"
                        >
                          <a href="/covid">Covid 19 Safety</a>
                        </li>
                      </ul>
                    </li>
                     {/* <li
                      id="menu-item-247"
                      className="nav-column menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-247"
                    >
                      <a>More</a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-1181"
                          className="nav-column-item nav-divider menu-item menu-item-type-custom menu-item-object-custom menu-item-1181"
                        >
                          {/* <a href="#">Cardholder Login</a> 
                        </li>
                      </ul>
                    </li>  */}
                  </ul>
                </div>
              </nav>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <nav className="footer-social" aria-label="Footer Social">
               <p>Location:</p>
               <p>Pathio District, จ.ชุมพร 86160</p>
              </nav>{" "}
            </div>
          </div>
          <section className="global-disclaimer">
            <div className="container">
              <div className="row">
                <div className="col py-2 mt-4 mb-3 background-color-neutral-10">
                  <div className="disclaimer">
                    <p>
                      The ROCKETSPEED Jet 25-Hour Card and ROCKETSPEED Jet
                      25-Hour Plus Card are programs of ROCKETSPEED Jet, LLC
                      (Rocketspeed®), an “Air Charter Broker” under DOT
                      regulations. ROCKETSPEED acts as authorized agent for the
                      Cardholder, which is the “Charterer” under DOT
                      regulations, to contract for transportation services with
                      FAR Part 135 Direct Air Carriers that operate and exercise
                      full operational control over those flights at all times.
                      Flights will be sourced through the proprietary
                      ROCKETSPEED Certified™ Network of Direct Air Carriers that
                      meet all FAA or comparable foreign safety standards and
                      additional safety standards established by ROCKETSPEED.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col">
              <nav className="footer-legal" aria-label="Footer Legal">
                <span>
                  Copyright 2024 • ROCKETSPEED Jet. All rights reserved.
                </span>
                {/* <div className="menu-footer-legal-container">
                  <ul id="menu-footer-legal" className="nav">
                    <li
                      id="menu-item-1892"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-1892"
                    >
                      <a rel="privacy-policy" href="legal/index.html">
                        Privacy Policy
                      </a>
                    </li>
                    <li
                      id="menu-item-1896"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1896"
                    >
                      <a href="/cookie">Cookie Policy</a>
                    </li>
                  </ul>
                </div> */}
              </nav>{" "}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
