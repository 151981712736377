import React from 'react'

const Technology = () => {
  return (
    <div>
    <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
            <section className="primary-hero primary-hero text-dark">
    <div className="image-container">
          <span className="scrim-light-ttb-1 scrim-height-30-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/02/1.3_Why_Technology_Hero_Mobile_x2.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/02/1.3_Why_Technology_Hero-1024x499.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/02/1.3_Why_Technology_Hero.jpg"/>
      <img
        data-src="../wp-content/uploads/2022/02/1.3_Why_Technology_Hero-1024x499.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    <div className="container">
    <div className="row">
            <div className="col-12 col-lg-5">
        <div
          className="content-container"
          data-gtm-component-title="Innovating from the start"
          data-gtm-section-title=""
        >
          <h1 className="">Innovating from the start</h1>
          
          <div className="body-copy body-xl col-lg-9 px-0"><p><span style={{fontWeight: 400}}>We introduced the industry’s first private jet card 25 years ago, offering the value and flexibility of purchasing air travel time in hourly increments while paying only for hours flown. We continue to innovate today to ensure you the safest, most convenient, and most comfortable private jet experience.</span></p>
</div>
                    <div className="cta-container">
            
          </div>
                  </div>
      </div>
    </div>
  </div>
</section>
              <section className="promo-with-cropped-circle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-lg-5 offset-lg-1 order-md-2">
        <div className="image-container image-align-top">
                    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.3_Why_Technology_Booking-800x800.jpg" alt="" className="img-fluid rounded-circle lazyload"/>
        </div>
      </div>
      <div
        className="text-container col-md-6 col-lg-4 offset-lg-1 order-md-1 "
        data-gtm-component-title="Real-time booking and more"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Real-time booking and more</h2>
        
        <div className="body-copy"><p><span style={{fontWeight: 400}}>Our mobile application lets you book private jet travel on-demand, as well as receive flight details, immediate assistance through a 24/7 live chat feature, and localized recommendations for partner benefits. </span></p>
</div>
        <div className="cta-container">
      
        <div className="download-cta">
                              <a href="https://apps.apple.com/us/app/sentient-jet/id1123758849" target="_blank" className="btn btn-primary btn-app-apple" data-gtm-click="link_click" data-gtm-event="app_download" data-gtm-click-text="Download in App Store">Download in App Store</a>
                                      <a href="https://play.google.com/store/apps/details?id=com.sentientjet&amp;hl=en_US&amp;gl=US" target="_blank" className="btn btn-primary btn-app-google" data-gtm-click="link_click" data-gtm-event="app_download" data-gtm-click-text="Get it on Google Play">Get it on Google Play</a>
                  </div>
      </div>
      </div>
    </div>
  </div>
</section>
          <section className="promo-with-unbounded-photo align-left">
    <div className="image-container">
    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.3_Why_Technology_Data_Tools_2x-1024x585.jpg" alt="" className="img-fluid lazyload"/>
  </div>
    <div className="container">
    <div className="row">
      <div
        className="col-md-4 offset-md-1"
        data-gtm-component-title="Digital tools at your fingertips"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Digital tools at your fingertips</h2>
        
        <div className="body-lg"><p><span style={{fontWeight: 400}}>Card Owners enjoy a seamless experience with text-based quoting and booking, as well as an exclusive Card Owner web experience that includes easy access to account details, flight bookings, and all their jet card information.</span></p>
</div>
              </div>
    </div>
  </div>
</section>
          <section className="promo-with-unbounded-photo align-right">
    <div className="image-container">
    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/1.3_Why_Technology_Data_Safety_2x-1024x585.jpg" alt="" className="img-fluid lazyload"/>
  </div>
    <div className="container">
    <div className="row">
      <div
        className="col-md-4 offset-md-7"
        data-gtm-component-title="Our data takes safety to the next level"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Our data takes safety to the next level</h2>
        
        <div className="body-lg"><p><span style={{fontWeight: 400}}>Your safety is paramount at Sentient. That’s why we leverage a vast database of proprietary flight data to help us continually improve and develop new safety measures and protocols. We’ve also developed health safety protocols like touchless travel to ensure you have a safe and healthy trip every time you fly with us.</span></p>
</div>
        <div className="cta-container">
      <a href="../private-jet-safety/index.html" target="_self" className="btn btn-tertiary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="More about ROCKETSPEED Safety">More about ROCKETSPEED Safety</a>
  </div>
      </div>
    </div>
  </div>
</section>
          <section className="cta-blade text-dark">
          <div className="image-container">
                  <span className="scrim-light-ltr-1 scrim-width-50-1" aria-hidden="true"></span>
                        <picture>
          <source media="(max-width: 768px)" srcset="./wp-content/uploads/2022/02/1.3_Why_Technology_CTA_FamilMobile.jpg"/>
          <source media="(max-width: 1024px)" srcset="./wp-content/uploads/2022/02/1.3_Why_Technology_CTA_Family-1024x256.jpg"/>
          <source media="(min-width: 1025px)" srcset="./wp-content/uploads/2022/02/1.3_Why_Technology_CTA_Family.jpg"/>
          <img
            src="../wp-content/uploads/2022/02/1.3_Why_Technology_CTA_Family-1024x256.jpg"
            alt=""/>
        </picture>
      </div>
    
    <div className="container">
      <div className="row">
        
        <div className="col-11 col-lg-6">
          <div className="content-container" data-gtm-component-title="Join us for a more innovative way to fly">
            <h2 className="">Join us for a more innovative way to fly</h2>
                          <div className="cta-container">
                <a href="../private-jet-cards/index.html" target="_self" className="btn btn-outline-secondary" data-gtm-click="link_click" data-gtm-event="" data-gtm-click-text="Get started">Get started</a>
              </div>
                      </div>
        </div>
      </div>
    </div>
  </section>
              </main>
              </div>
    </div>
    </div>
  )
}

export default Technology