import React from 'react'

const Covid = () => {
  return (
    <div>
        <div className="wrap" role="document">
      <div className="content">
        <main className="main" id="main">
            <section className="primary-hero primary-hero text-dark">
    <div className="image-container">
          <span className="scrim-light-ttb-1 scrim-height-30-1" aria-hidden="true"></span>
            <picture>
      <source media="(max-width: 768px)" data-srcset="./wp-content/uploads/2022/01/a-more-thoughtful-bg-desktop-1024x499.jpg"/>
      <source media="(max-width: 1024px)" data-srcset="./wp-content/uploads/2022/01/a-more-thoughtful-bg-desktop-1024x499.jpg"/>
      <source media="(min-width: 1025px)" data-srcset="./wp-content/uploads/2022/01/a-more-thoughtful-bg-desktop-1024x499.jpg"/>
      <img
        data-src="./wp-content/uploads/2022/03/3.4_Safety_Covid_Hero_2x-1-1024x499.jpg"
        alt=""
        className="lazyload"/>
    </picture>
  </div>
    <div className="container">
    <div className="row">
            <div className="col-12 col-lg-5">
        <div
          className="content-container"
          data-gtm-component-title="Our response to COVID-19"
          data-gtm-section-title=""
        >
          <h1 className="">Our response to COVID-19</h1>
          
          <div className="body-copy body-xl col-lg-9 px-0"><p><span style={{fontWeight: 400}}>While the COVID-19 pandemic has created a constantly evolving landscape across private aviation, ROCKETSPEED Jet has remained steadfast in our commitment to going above and beyond to protect our cardholders, passengers and employees.</span></p>
</div>
                    <div className="cta-container">
            
          </div>
                  </div>
      </div>
    </div>
  </div>
</section>
              <section className="feature-list" data-gtm-component-title="Your health and safety is our top priority">
  <div className="container">
    <div className="row">
      <div className="col">
        <h2 className="icon-heading">Your health and safety is our top priority</h2>
        <div className="body-copy">To deal with the challenges brought on by COVID-19, we’ve established a Safety Operations Task Force, comprised of medical professionals who meet daily to discuss operational information, provide guidance to staff, and make updates to our pandemic response plan.
</div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <strong className="">The Task Force is focused on:</strong>
      </div>
    </div>
        <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/icon-sanitize.svg" alt="" className=" lazyload"/>
              <figcaption className="">Aircraft disinfection and updated routine cleaning procedures</figcaption>
            </figure>
          </div>
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/icon-mask.svg" alt="" className=" lazyload"/>
              <figcaption className="">Crewmember-passenger interaction protocols</figcaption>
            </figure>
          </div>
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/icon-shield.svg" alt="" className=" lazyload"/>
              <figcaption className="">Transmission prevention</figcaption>
            </figure>
          </div>
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/icon-cross.svg" alt="" className=" lazyload"/>
              <figcaption className="">Health monitoring and maintenance</figcaption>
            </figure>
          </div>
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
            <figure className="feature-container d-flex flex-lg-row flex-column align-items-center justify-content-center">
              <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/icon-trace.svg" alt="" className=" lazyload"/>
              <figcaption className="">Contact tracing protocols</figcaption>
            </figure>
          </div>
                  </div>
             <div className="row">
        <div className="col">
          <div className="cta-container">
            
          </div>
        </div>
      </div>
            </div>
</section>
          <section className="promo-with-unbounded-photo align-left">
    <div className="image-container">
    <img src="#" data-src="https://sentient.com/wp-content/uploads/2022/02/us-map-1024x585.jpg" alt="" className="img-fluid lazyload"/>
  </div>
    <div className="container">
    <div className="row">
      <div
        className="col-md-4 offset-md-1"
        data-gtm-component-title="Monitoring flight destinations"
        data-gtm-section-title=""
      >
        <h2 className="icon-heading">Monitoring flight destinations</h2>
        
        <div className="body-lg"><p><span style={{fontWeight: 400}}>ROCKETSPEED Jet’s Emergency Response Task Force and Network of Certified Operators constantly monitor information from public health organizations and related experts to determine which areas around the globe we may operate in at this time.</span></p>
</div>
              </div>
    </div>
  </div>
</section>
              </main>
              </div>
    </div>
    </div>
  )
}

export default Covid